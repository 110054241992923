import styles from './rankingspage.module.css'

export default function RankingsPage()
{
    return  <div className={styles.HomePageContent}>
    <div className={styles.search}>
    <button>
    <i className="fa fa-search" aria-hidden="true"></i>
    </button>
    <input type='text'placeholder="Search Contestant ...."/>
    </div>
    
    <div className={styles.cardContent}>
    
    
            <button className={styles.card}>
                <div className={styles.image}>
    
                </div>
                <div className={styles.rightContent}>
                <div className={styles.contestantDetails}>
                    <span className={styles.nickname}><strong>Name:</strong> Nickname</span>
                    <span className={styles.code}><strong>Code:</strong> 1001</span>
                </div>
                <div className={styles.votes}><strong>Votes:</strong> 23,34543432,</div>
                <div className={styles.action}><span>Click to Vote</span></div>
                </div>
            </button>
            <button className={styles.card}>
                <div className={styles.image}>
    
                </div>
                <div className={styles.rightContent}>
                <div className={styles.contestantDetails}>
                    <span className={styles.nickname}><strong>Name:</strong> Nickname</span>
                    <span className={styles.code}><strong>Code:</strong> 1001</span>
                </div>
                <div className={styles.votes}><strong>Votes:</strong> 23,34543432,</div>
                <div className={styles.action}><span>Click to Vote</span></div>
                </div>
            </button>
            <button className={styles.card}>
                <div className={styles.image}>
    
                </div>
                <div className={styles.rightContent}>
                <div className={styles.contestantDetails}>
                    <span className={styles.nickname}><strong>Name:</strong> Nickname</span>
                    <span className={styles.code}><strong>Code:</strong> 1001</span>
                </div>
                <div className={styles.votes}><strong>Votes:</strong> 23,34543432,</div>
                <div className={styles.action}><span>Click to Vote</span></div>
                </div>
            </button>
            </div>
        </div>
}