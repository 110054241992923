
import HistoryPage from './Pages/HistoryPage'
import HomePage from './Pages/HomePage';
import RankingsPage from './Pages/RankingsPage';
import NavBar from './Components/NavBar/NavBar';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import VotingPage from './Pages/VotingPage';
import IframePage from './Pages/IframePage';
import Img from "./logo.png"

function App() {
  return (
    <div className="App">
    <div className='header'>
      <img src={Img} alt='NEUTEK SOLUTIONS' style={{height:20}}/>
      <div className='event'>
      Diasa
    </div>
    </div>
 
    {/* <div className='nav'>
    <NavBar/>
    </div> */}
   
    <div className='pageContent'>
    <BrowserRouter>
   <Routes>
   <Route element={<NavBar/>}>
     <Route path="/" element={<HomePage />} />
     <Route path="/history" element={<HistoryPage />} />
     <Route path="/rankings" element={<RankingsPage />} />
     <Route path="/voting-page" element={<VotingPage />} />
     <Route path="/iframe-page" element={<IframePage />} />
     </Route>
     </Routes>
     </BrowserRouter>

    </div>

    </div>
  );
}

export default App;
