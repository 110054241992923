import styles from "./ReactLoader.module.css"
import ReactLoading from "react-loading";

export function SpokesLOader()
{
    return      <div className={styles.spinner}>
    <ReactLoading
      type={"spokes"}
      height={100}
      width={100}
      // color={"rgb(155, 208, 252,0.3)"}
    />
  </div>
}