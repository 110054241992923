
import { useEffect, useState } from "react";
import styles from "./votingpage.module.css"
import {ErrorAlert, SuccessAlert} from "../MainComponents/Alerts/Alerts"
import {HTTPClient} from "../Utils/HTTPClient/HTTPFetchClient"
import { useLocation, useNavigate } from "react-router-dom";
import { SpokesLOader } from "../MainComponents/ReactLoader/ReactLoader";

export default function IframePage()
{
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    
   
  
    useEffect(() => {
      setData(location.state);
      if(location.state==null)
      {
        navigate('/')
      }else
      {
      setPreview(location.state.profile)
      }
    }, [location.state]);
    async function Store(event) {
        event.preventDefault();

        setIsLoading(true);
        try {
          const formData = new FormData();
          formData.append('voting_platform','web');
          formData.append('event_code','dia9380');
          formData.append('contestant_id',data.id);
          formData.append('contestant_code',data.code)
          formData.append('vote',event.target[0].value)

          let resp = await HTTPClient(
            `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STORE_VOTE}`,
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
              },
              body: formData,
            }
          );
          let response = await resp.json();
          console.log(response.data)
    
          if (!resp.ok) {
            setIsLoading(false);
            let errMsg = response.errors.join("<br/>");
            ErrorAlert(errMsg, 5000);
            return;
          }
    
         
        } catch (ex) {
          setIsLoading(false);
          console.log(ex);
        }
        setIsLoading(false);
      }

    return <div className={styles.HomePageContent}>
<div className={styles.iframeCard}>
<div className={styles.iframeContent}>
{isLoading?<SpokesLOader/>:""}
<iframe src={location.state?location.state.checkoutDirectUrl:""} className={styles.iframeContent}></iframe> 
        </div>
        <button className={styles.goBack} onClick={() => navigate(-1)}>Go Back</button> 
        </div>
    </div>
}