
import { useEffect, useState } from "react";
import { ErrorAlert} from "../MainComponents/Alerts/Alerts";
import { HTTPClient } from "../Utils/HTTPClient/HTTPFetchClient";
import styles from "./homepage.module.css"
import { SpokesLOader } from "../MainComponents/ReactLoader/ReactLoader"
import { useNavigate } from "react-router-dom";
import Img from "../vote3.jpg";

export default function HomePage()
{
    const [data, setData] = useState([]);
    const [allData, setAllData]=useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [search, setSearch] = useState("");

    async function   LoadPageData() {
      
    
        setIsLoading(true);
        try {
      
          let resp = await HTTPClient(
            `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_APPROVED_CONTESTANT}`,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
              },
      
            }
          );
          let response = await resp.json();
          setAllData(response.data)
          PopulateTable(response.data)
          if (!resp.ok) {
      
            let errMsg = response.errors.join("<br/>");
            ErrorAlert(errMsg, 5000);
            return;
          }
    
      
        } catch (ex) {
       
          console.log(ex);
        }
     
      }
      useEffect(() => {
   
        LoadPageData();
     
      }, [])

      const handleSearch = async () => {
        try {
          setIsLoading(true);
          let newTable = allData.filter((row) => {
            //   console.log('search',row.firstname.includes(search))
            return (
              (row.first_name?row.first_name.toLowerCase().includes(search.toLowerCase()):"") ||
              (row.last_name?row.last_name.toLowerCase().includes(search.toLowerCase()):"") ||
              (row.nickname ? row.nickname.toLowerCase().includes(search.toLowerCase()) : "") ||
              (row.code ? row.code.toLowerCase().includes(search.toLowerCase()) : "") 
            );
          });
          PopulateTable(newTable);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        handleSearch();
      }, [search]);

      function PopulateTable(newTable) {
        setIsLoading(true);

        let TableData = newTable.map((table, index) => {
          return  <button key={table.id+"0"} className={styles.card}  onClick={() => navigate("/voting-page", { state: table })}>
            <div  key={table.id+"1"}   className={styles.image}>
            <img key={table.id+"2"}   className={styles.image} src={table.profile} alt="profile" />
            </div>
            <div  key={table.id+"3"} className={styles.contestantDetails}>
                <span key={table.id+"4"} className={styles.nickname}>{table.nickname?table.nickname.toUpperCase():""}</span>
                <span key={table.id+"5"} className={styles.code}><span>Code:</span>{table.code}</span>
            </div>
        </button>
      
          
        });
      
        setData(TableData);
        setIsLoading(false)
     
      }
    return <div className={styles.HomePageContent}>
<div className={styles.search}>
<button>
<i className="fa fa-search" aria-hidden="true"></i>
</button>
<input    onChange={(e) => setSearch(e.target.value)} type='text'placeholder="Search Contestant ...." required/>

</div>
        <div className={styles.cardContent}>
      
        {isLoading?<SpokesLOader/>:""}
            {data}
        </div>
    </div>
}