import { Link, Outlet } from 'react-router-dom'
import styles from './navbar.module.css'
export default function NavBar()
{
return <>

<div className={styles.container}>
  <div className={styles.nav}>
  <Link className={styles.button} to={'/'}>Home</Link>
    {/* <Link className={styles.button} to={'/rankings'}>Rankings</Link> */}
    {/* <Link className={styles.button} to={'/history'}>History</Link> */}
    </div>
    </div>
<div className={styles.pageContent}>
<Outlet/>
</div>

</>

}